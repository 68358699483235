<template>
    <Layout>
      <template v-if="this.$route.name == 'add-metaContent'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="expert"
                :finishButtonText="this.$route.name == 'add-metaContent' ? 'Save Data' : 'Update Data'"
                back-button-text="Go Back!" next-button-text="Go Next!">
                <tab-content title="Meta Content" icon="mdi mdi-account-details"
                  :before-change="() => validateFormOne()">
                  <div class="row">
                    <b-form-group class="col-6">
                      <label for="input-2">Page Name <span style="color: red">*</span></label>
                      <b-form-input id="input-2" v-model="form.name" placeholder="Enter Page Name" :class="{
                        'is-invalid': submitted && $v.form.name.$invalid,
                      }"></b-form-input>
                      <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                        Page Name is required.
                      </div>
                    </b-form-group>
                    <b-form-group class="col-6">
                      <label for="input-2">Meta Tag Type <span style="color: red">*</span></label>
                      <b-form-input id="input-2" v-model="form.type" placeholder="Enter Meta tag type" :class="{
                        'is-invalid': submitted && $v.form.type.$invalid,
                      }"></b-form-input>
                      <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">
                        meta tag type is required.
                      </div>
                    </b-form-group>
                  </div>
  
                  <div class="row">
                    <b-form-group class="col-6 position-relative" id="input-group-9" label="Meta Title"
                      label-for="input-9">
                      <b-form-input :maxlength="190" id="input-9" v-model="form.meta_title"
                        placeholder="Enter Meta Title"></b-form-input>
                      <div class="text-right">
                        <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                          'badge-success': form.meta_title.length !== 190,
                          'badge-danger': form.meta_title.length === 190
                        }">
                          You typed
                          {{ form.meta_title.length }} out of 190 chars.
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-6 position-relative" id="input-group-11" label="Meta Keywords"
                      label-for="input-11">
                      <b-form-textarea id="input-11" v-model="form.meta_keywords"
                        placeholder="Enter Meta Keywords">
                      </b-form-textarea>
                      <!-- <div class="text-right">
                        <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                          'badge-success': form.meta_keywords.length !== 250,
                          'badge-danger': form.meta_keywords.length === 250
                        }">
                          You typed
                          {{ form.meta_keywords.length }} out of 250 chars.
                        </p>
                      </div> -->
                    </b-form-group>
                    <b-form-group class="col-12 position-relative" label="Meta Description" label-for="input-10">
                      <b-form-textarea id="textarea" :maxlength="400" v-model="form.meta_description"
                        placeholder="Enter Meta Description" rows="3"></b-form-textarea>
                      <div class="text-right">
                        <p v-if="form.meta_description" class="badge position-absolute" style="top: 4px; right: 13px"
                          :class="{
                            'badge-success': form.meta_description.length !== 400,
                            'badge-danger': form.meta_description.length === 400
                          }">
                          You typed
                          {{ form.meta_description.length }} out of 400 chars.
                        </p>
                      </div>
                    </b-form-group>
                  </div>

                </tab-content>
  
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../mixins/request";
  import expertMixin from "../../../mixins/ModuleJs/metatag";
  import { required, email } from "vuelidate/lib/validators";
  import { FormWizard, TabContent } from "vue-form-wizard";
  
  export default {
    mixins: [MixinRequest, expertMixin],
    data() {
      return {
        submitted: false,
        store: false,
        title1: "Add Meta Content",
        title2: "Edit Meta content",
        items: [
          {
            text: "List",
            href: "/metatag",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    validations: {
      form: {
        community_selected: { required },
        name: { required },
        nationality: { required },
        email: { email },
      },
    },
    components: {
      PageHeader,
      Layout,
      FormWizard,
      TabContent,
    },
    methods: {
      validateFormOne() {
        this.submitted = true;
        if (this.$v.form.name.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        return true;
      },
      validateFormTwo() {
        this.store = true;
        if (this.$v.form.community_selected.$invalid || this.$v.form.nationality.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        return true;
      },
    },
  };
  </script>
  