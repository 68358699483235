var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-metaContent')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"expert",attrs:{"color":"#556ee6","enctype":"multipart/form-data","finishButtonText":this.$route.name == 'add-metaContent' ? 'Save Data' : 'Update Data',"back-button-text":"Go Back!","next-button-text":"Go Next!"},on:{"on-complete":_vm.submitData}},[_c('tab-content',{attrs:{"title":"Meta Content","icon":"mdi mdi-account-details","before-change":() => _vm.validateFormOne()}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Page Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.name.$invalid,
                  },attrs:{"id":"input-2","placeholder":"Enter Page Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Page Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Meta Tag Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.type.$invalid,
                  },attrs:{"id":"input-2","placeholder":"Enter Meta tag type"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.submitted && !_vm.$v.form.type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" meta tag type is required. ")]):_vm._e()],1)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-9","label":"Meta Title","label-for":"input-9"}},[_c('b-form-input',{attrs:{"maxlength":190,"id":"input-9","placeholder":"Enter Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_title)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_title.length !== 190,
                      'badge-danger': _vm.form.meta_title.length === 190
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_title.length)+" out of 190 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-11","label":"Meta Keywords","label-for":"input-11"}},[_c('b-form-textarea',{attrs:{"id":"input-11","placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}})],1),_c('b-form-group',{staticClass:"col-12 position-relative",attrs:{"label":"Meta Description","label-for":"input-10"}},[_c('b-form-textarea',{attrs:{"id":"textarea","maxlength":400,"placeholder":"Enter Meta Description","rows":"3"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_description)?_c('p',{staticClass:"badge position-absolute",class:{
                        'badge-success': _vm.form.meta_description.length !== 400,
                        'badge-danger': _vm.form.meta_description.length === 400
                      },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_description.length)+" out of 400 chars. ")]):_vm._e()])],1)],1)])],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }